:root[webchat-theme='theme1'] {
  --swiper-container-overflow: hidden;
  --swiper-container-position: relative;
  --swiper-container-padding-left: 1px;
  --swiper-wrapper-div-width: 200px;
  --swiper-slide-justify-content: space-between;
  --swiper-slide-img-justify-content: center;
  --swiper-slide-padding: 16px;
  --swiper-slide-gap: 16px;
  --swiper-slide-img-width: 200px;
  --swiper-slide-img-height: 109px;
  --swiper-slide-img-border-radius: 8px;
  --swiper-slide-info-visibility: flex;
  --swiper-slide-info-width: 200px;
  --swiper-slide-info-height: 90px;
  --swiper-slide-info-flex-direction: column;
  --swiper-slide--info-text-align: flex-start;
  --swiper-slide--info-justify-content: start;
  --swiper-slide-info-position: relative;
  --swiper-slide-info-gap: 4px;
  --swiper-slide-title-font: var(--font-big-bold);
  --swiper-slide-title-color: var(--pure-black);
  --swiper-slide-sub-title-font: var(--font-normal-medium);
  --swiper-slide-sub-title-color: var(--pure-black);
  --swiper-slide-description-font: var(--font-small-regular);
  --swiper-slide-description-color: var(--text-secondary);
  --swiper-slide-option-group-visibility: flex;
  --swiper-slide-option-group-justify-content: end;
  --swiper-slide-option-group-gap: 8px;
  --swiper-slider-bubble-option-width: 168px;
  --swiper-slider-bubble-option-padding: 7.5px 16px;
}
