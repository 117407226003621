:root {
  --carousel-left-arrow: url(/assets/images/arrow-left.svg);
  --carousel-right-arrow: url(/assets/images/arrow-right.svg);
}

.carousel-container ul {
  list-style: none;
  padding-left: 0px;
}

.featured-list img {
  width: 100%;
}

.carousel-container {
  max-width: 450px;
  margin: 0 auto;
}

.carousel-radio {
  position: absolute;
  bottom: 0;
  left: -9999px;
}

/* SLIDESHOW
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.featured-wrapper {
  position: relative;
}

.featured-wrapper .featured-list {
  display: grid;
  padding: 5px 50px 25px 50px;
}

.featured-wrapper .featured-list li {
  grid-column: 1;
  grid-row: 1;
  opacity: 0;
  transition: opacity 0.25s;
}

.featured-wrapper .arrows label::before,
.featured-wrapper .arrows label::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: black;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-color: white;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.featured-wrapper .arrows label::before {
  left: 5px;
}

.featured-wrapper .arrows {
  height: 0px !important;
}

.featured-wrapper .arrows label::after {
  right: 5px;
}

.featured-wrapper .dots {
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 3;
}

.featured-wrapper .dots li:not(:last-child) {
  margin-right: 8px;
}

.featured-wrapper .arrows label {
  cursor: pointer;
}

.featured-wrapper .dots label {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid white;

  transition: background 0.25s;
  cursor: pointer;
}

.featured-wrapper .dots label:hover {
  background: currentColor;
}
