:root[webchat-theme='theme1'] {
  --message-gap: 4px;
  --message-incoming-margin: 0 0 8px 24px;
  --message-outgoing-margin: 0 24px 8px 0;
  --message-avatar-width: 24px;
  --message-incoming-position: relative;
  --message-outgoing-direction: row-reverse;
  --message-incoming-direction: row;
  --bubble-content-direction: column;
  --bubble-box-shadow: none;
  --bubble-content-font: var(--font-normal-regular);
  --bubble-content-gap: 0;
  --bubble-content-margin-bottom: 4px;
  --message-time-font: var(--font-extra-small-regular);
  --message-time-color: var(--text-secondary);
  --message-time-outgoing-color: var(--white-shades-64);
  --message-outgoing-avatar-visibility: none;
  --bubble-incoming-text-color: var(--text-dark);
  --bubble-outgoing-text-color: var(--text-primary);
  --bubble-outgoing-back-color: var(--brand-primary-3-100);
  --bubble-incoming-back-color: var(--dark-shades-4);
  --bubble-incoming-max-width: 206px;
  --message-outgoing-avatar: none;
  --message-incoming-avatar: url('./../../icons/avatar-1.svg') no-repeat center center;
}
