:root[webchat-theme='theme1'] {
  --bubble-option-group-gap: 8px;
  --bubble-option-group-margin: 0 0 16px 52px;
  --bubble-option-align-items: center;
  --bubble-option-justify-content: center;
  --bubble-option-font: var(--font-normal-medium);
  --bubble-option-padding: 5.5px 16px;
  --bubble-option-text-align: center;
  --bubble-option-color: var(--text-brand);
  --bubble-option-back-color: var(--pure-white);
  --bubble-option-radius: 8px;
  --bubble-option-outline: 2px solid var(--border-primary);
  --bubble-option-transition: outline 0.5s ease;
  --bubble-option-back-color-hover: var(--pure-white);
  --bubble-option-outline-hover: 2px solid var(--brand-primary-3-100);
  --bubble-option-color-hover: var(--text-brand);
  --bubble-option-back-color-focus: var(--pure-white);
  --bubble-option-color-focus: var(--text-brand);
  --bubble-option-outline-focus: 2px solid var(--brand-primary-3-100);
  --bubble-option-not-selected-opacity: 0.4;
}
