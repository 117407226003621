:root[webchat-theme='theme1'] {
  --bubble-outgoing-audio-padding: 8px 12px 4px 8px;
  --bubble-outgoing-audio-width: 190px;
  --bubble-incoming-audio-padding: 8px 12px 4px 8px;
  --bubble-incoming-audio-width: 190px;
  --bubble-content-audio-gap: 8px;
  --bubble-content-audio-margin-bottom: 0;
  --player-gap: 8px;
  --player-padding: 0;
  --player-align-items: center;
  --play-background-padding: 5px 4.68px 4.78px 5.1px;
  --play-background-back-color: var(--white-shades-16);
  --play-incoming-background-back-color: var(--dark-shades-16);
  --play-background-radius: 50%;
  --player-wrapper-justify-content: space-between;
  --timer-incoming-color: var(--brand-primary-3-100);
  --timer-color: var(--pure-white);
  --timer-font: var(--font-small-medium);
  --timer-padding: 0;
  --play-width: 14.22px;
  --play-height: 14.22px;
  --play-icon: url('./../../icons/play-outline.svg');
  --play-incoming-icon: url('./../../icons/play-outline.svg');
  --pause-width: 14.22px;
  --pause-height: 14.22px;
  --pause-icon: url('./../../icons/pause.svg');
  --pause-incoming-icon: url('./../../icons/pause.svg');
  --player-incoming-text-color: var(--bubble-content-font);
  --player-text-color: var(--text-primary);
  --player-text-font: var(--font-normal-regular);
  --player-text-margin-bottom: 8px;
}
