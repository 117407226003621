:root[webchat-theme='theme1'] {
  --modal-back-color: var(--dark-shades-80);
  --modal-z-index: 1;
  --modal-close-top: 16px;
  --modal-close-rigt: 16px;
  --modal-close-size: 24px;
  --modal-close-background: url('./../../icons/close.svg') no-repeat center center;
  --modal-slider-top: 50%;
  --modal-slider-left: 50%;
  --modal-slider-transform: translate(-50%, -50%);
  --modal-slider-width: calc(100% - 32px);
  --modal-slider-visibility: flex;
  --modal-slider-flex-direction: column;
  --modal-slider-max-height: 100%;
  --modal-slides-position: relative;
  --modal-slides-visibility: flex;
  --modal-slides-justify-content: center;
  --modal-slides-height: 100%;
  --modal-slides-max-width: calc(100% - 80px);
  --modal-slide-size: auto;
  --modal-slide-img-max-width: 100%;
  --modal-slide-img-height: auto;
  --modal-slide-img-visibility: block;
  --modal-slide-img-margin: 0 auto;
  --modal-controls-position: absolute;
  --modal-controls-top: 50%;
  --modal-controls-transform: translateY(-50%);
  --modal-controls-width: 100%;
  --modal-controls-display: flex;
  --modal-controls-justify-content: space-between;
  --modal-controls-prev-background: url('./../../icons/arrow-left.svg') no-repeat center center;
  --modal-controls-next-background: url('./../../icons/arrow-right.svg') no-repeat center center;
  --modal-control-btn-size: 24px;
  --modal-control-btn-border: none;
  --modal-slide-counter-bottom: 8px;
  --modal-slide-counter-right: 8px;
  --modal-slide-counter-color: var(--text-primary);
  --modal-slide-counter-font: var(--font-normal-medium);
  --modal-slide-counter-back-color: var(--dark-shades-48);
  --modal-slide-counter-radius: 8px;
  --modal-slide-counter-padding: 2px 13px;
}
