.ac-image.ac-selectable {
  cursor: pointer;
}

.ac-image.ac-selectable:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.ac-image.ac-selectable:active {
  background-color: rgba(0, 0, 0, 0.15);
}

.ac-media-poster.empty {
  height: 200px;
  background-color: #f2f2f2;
}

.ac-media-playButton {
  width: 56px;
  height: 56px;
  border: 1px solid #eeeeee;
  border-radius: 28px;
  box-shadow: 0px 0px 10px #eeeeee;
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  cursor: pointer;
}

.ac-media-playButton-arrow {
  color: black;
}

.ac-media-playButton:hover {
  background-color: white;
}

/* Carousel */

.carousel-caption p {
  font-size: 20px;
  line-height: 1.4;
}

/* Make .svg files in the carousel display properly in older browsers */

.carousel-inner .item img[src$='.svg'] {
  width: 100%;
}

/* Hide/rearrange for smaller screens */

@media screen and (max-width: 767px) {
  /* Hide captions */
  .carousel-caption {
    display: none;
  }
}

.herocard > .title {
  font-weight: bold;
}

.herocard > img {
  max-width: 100%;
}

.herocard > .subtitle {
  font-weight: lighter;
}

.herocard button[type='imBack'] {
  margin-top: 6px;
}

/*adaptive card style*/

.ac-carousel {
  margin: 0 10px;
  width: 100%;
  max-width: 285px;
}

.ac-carousel-item {
  padding: 5px 0px;
  text-align: center;
}
