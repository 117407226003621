:root[webchat-theme='theme1'] {
  --opener-width: 64px;
  --opener-height: 64px;
  --opener-back-color: var(--brand-primary-3-100);
  --opener-outline: none;
  --opener-radius: 100px;
  --opener-shadow: none;
  --opener-transition: none;
  --opener-position-bottom: 30px;
  --opener-position-right: 64px;
  --opener-icon-close-background: url('./../../icons/close.svg') no-repeat center center;
  --opener-icon-count-visibility: unset;
  --opener-icon-count-left: 46px;
  --opener-icon-count-bottom: 46px;
  --opener-icon-count-width: 20px;
  --opener-icon-count-height: 19px;
  --opener-icon-count-padding-top: 1px;
  --opener-icon-count-back-color: var(--icon-dark);
  --opener-icon-count-radius: 24px;
  --opener-icon-count-font: var(--font-small-medium);
  --opener-icon-count-color: var(--pure-white);
  --opener-info-text-visibility: unset;
  --opener-info-text-bottom: 45px;
  --opener-info-text-right: 140px;
  --opener-info-text-font: var(--font-normal-regular);
  --opener-info-text-padding: 4px 9px;
  --opener-info-text-radius: 4px;
  --opener-info-text-outline: 1px solid var(--border-primary);
  --opener-info-text-color: var(--pure-black);
  --opener-info-text-shadow: 0 4px 12px 0 var(--shadow-100);
}
