:root[webchat-theme='theme1'] {
  --bubble-content-chat-dots-height: 10px;
  --bubble-content-chat-dots-gap: 4px;
  --bubble-content-chat-dots-margin: 0;
  --chat-dot-width: 6px;
  --chat-dot-height: 6px;
  --chat-dot-back-color: var(--brand-primary-3-100);
  --chat-dot-incoming-back-color: var(--brand-primary-3-100);
  --chat-dot-radius: 50%;
  --chat-dot-animation: wave 1.7s infinite;
  --chat-dot-animation-delay-child-2: 0.2s;
  --chat-dot-animation-delay-child-3: 0.4s;
  --animation-start-top: 30%;
  --animation-end-top: -30%;
}
