:root[webchat-theme='theme1'] {
  --bottom-padding: 16px;
  --bottom-gap: 8px;
  --input-container-width: calc(100% - 68px);
  --input-container-back-color: var(--pure-white);
  --input-container-border-radius: 16px;
  --input-container-padding: 8px 4px 8px 12px;
  --input-container-gap: 4px;
  --input-radius: none;
  --input-width: 100%;
  --input-color: var(--text-dark);
  --input-container-outline: 2px solid var(--border-primary);
  --input-font: var(--font-normal-regular);
  --input-focus: 2px solid var(--brand-primary-3-100);
  --input-actions-gap: 0;
  --input-container-actions-div-margin: 4px;
  --input-container-actions-record: url('./../../icons/microphone.svg');
  --input-container-actions-send: url('./../../icons/send.svg');
  --input-container-actions-add-file: url('./../../icons/addFile.svg');
  --input-container-actions-send-padding: 8px;
  --input-container-actions-send-width: 28px;
  --input-container-actions-send-radius: 16px;
  --input-actions-send-display: unset;
  --input-container-actions-send-background-color: var(--brand-primary-3-100);
  --input-actions-send-padding: 8px;
  --input-actions-send-max-width: 28px;
  --input-actions-send-width: 100%;
  --input-actions-send-height: 28px;
  --input-actions-send-radius: 16px;
  --input-actions-stop-display: unset;
  --input-container-actions-stop: url('./../../icons/stop.svg');
  --input-container-actions-stop-background-color: none;
  --input-actions-stop-padding: 8px 8px;
  --input-actions-stop-max-width: 28px;
  --input-actions-stop-width: 100%;
  --input-actions-stop-height: 28px;
  --input-actions-stop-radius: 16px;
  --input-actions-stop-outline: 2px solid var(--brand-primary-3-100);
  --input-actions-record-display: unset;
  --input-actions-add-file-display: unset;
  --input-actions-icon-size: 20px;
  --input-outline-focus: 2px solid var(--brand-primary-3-100);
  --input-placeholder-color: var(--text-place-holder);
}
