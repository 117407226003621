:root[webchat-theme='theme1'] {
  --swiper-button-top: 43%;
  --swiper-button-height: 28px;
  --swiper-button-width: 20px;
  --swiper-button-back-color: var(--icon-dark);
  --swiper-button-padding: 0 4px;
  --swiper-button-box-shadow: 0px 16px 16px 0px var(--shadow-200);
  --swiper-button-next-icon: url('./../../icons/arrow-right.svg') no-repeat center center;
  --swiper-button-prev-icon: url('./../../icons/arrow-left.svg') no-repeat center center;
  --swiper-button-prev-left: -8px;
  --swiper-button-next-right: 16px;
  --swiper-button-radius: 99px;
  --swiper-button-margin: 0;
  --swiper-button-back-size: 20px 20px;
  --swiper-button-disabled-back-color: var(--pure-white);
  --swiper-button-disabled-color: var(--pure-black);
}
