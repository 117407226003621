// --- entity-info-cards start ---
$entity-info-list-color: #7f81ae;
$entity-info-icon-color: #eff0f7;
$entity-info-create-import-generate-icon-color: #5864a6;

:host ::ng-deep .block-ui-wrapper {
  opacity: 0.3 !important;
}

.entity-info-list-card {
  border-radius: 6px;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.15);

  .entity-info-list-card-body {
    padding: 0;
    padding-inline-start: 50px;
    overflow-y: auto;

    .entity-info-search {
      padding: 35px 25.5px 5px;

      cai-search-list {
        .search-inputs {
          width: 300px !important;
        }
      }
    }

    .entity-info-list-container {
      overflow-y: auto;

      .entity-info-list {
        display: flex;
        flex-wrap: wrap;

        .entity-info-card {
          border-radius: 6px;
          box-shadow: 0px 0px 5px 3px rgba(127, 129, 174, 0.5);
          background-color: white;
          cursor: pointer;
        }

        .new-entity-info {
          width: 300px;
          height: 375px;
          margin: 30px 25.5px;

          .new-entity-info-card {
            width: 100%;
            height: 235px;
            margin-bottom: 15px;
            cursor: pointer;
          }

          .import-entity-info-card {
            width: 100%;
            height: 125px;
          }
        }

        .existing-entity-info-card {
          width: 300px;
          height: 375px;
          margin: 30px 25.5px;

          .entity-info-type {
            position: relative;

            .entity-info-type-icon {
              position: absolute;
              border-end-end-radius: 75%;
              background-color: $entity-info-icon-color;
              width: 35px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .entity-info-details {
            & > div {
              display: flex;
              justify-content: center;
              height: 30px;
            }
          }
        }

        .entity-info-content {
          height: 100%;
          padding: 30px 30px 0;
          display: flex;
          flex-direction: column;
        }

        .entity-info-card-actions {
          z-index: inherit !important;
          .action-buttons {
            position: absolute;
            right: 10px;
            top: 26px;
            display: flex;
            flex-direction: column-reverse;
            margin: 0;
          }

          .dropdown-action-buttons {
            overflow: inherit !important;
            button {
              pointer-events: auto !important;
            }

            .tooltip .tooltip-inner {
              width: 17em;
            }
          }

          .bubble-btn {
            transition: transform 0.3s;
          }

          .bubble-btn:hover {
            animation: bubble 0.4s ease-out;
          }

          @keyframes bubble {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.2);
            }
            100% {
              transform: scale(1);
            }
          }
        }

        .entity-info-header {
          font-family: Nunito Sans;
          font-size: 18px;
          font-weight: 600;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: center;
        }

        .entity-info-description {
          height: 100px;
          overflow-y: auto;
          word-break: break-word;
          text-align: center;
          align-content: space-around;
        }

        .entity-info-icon {
          display: inline-block;
          background-color: $entity-info-icon-color;
          color: $entity-info-create-import-generate-icon-color;
          border-radius: 50%;
          font-size: 22px;
          font-weight: 700;
          line-height: 75px;
          width: 75px;
          height: 75px;
          text-align: center;
          margin-top: 25px;
          margin-bottom: 45px;

          i {
            color: white !important;
          }

          &.new-entity-info-icon {
            line-height: 78px;
            margin-bottom: 0;
            background-color: $entity-info-create-import-generate-icon-color;
          }

          &.import-entity-info-icon {
            font-size: 20px;
            line-height: 50px;
            width: 50px;
            height: 50px;
            margin-top: 10px;
            margin-bottom: 0;
            background-color: $entity-info-create-import-generate-icon-color;
          }

          &.generate-entity-info-icon {
            background-color: $entity-info-create-import-generate-icon-color;
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 15px;
            padding-top: 1px;
            cursor: pointer;
          }

          &.disabled-generate-entity-info-icon {
            background-color: $entity-info-icon-color;
            cursor: default;
          }
        }
      }
    }
  }
}

.chat-flow {
  &.entity-info-list-position {
    inset-block-start: unset;
    inset-inline-end: 1px;
    bottom: 10px;
  }

  .message-content {
    height: calc(100vh - 325px) !important;
  }
}

.entity-info-list-card .entity-info-list-card-body {
  height: calc(100vh - 228px);
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .entity-info-list-card .entity-info-list-card-body {
    height: calc(100vh - 283px);
  }
}

@media only screen and (min-width: 1366px) {
  .entity-info-list-card .entity-info-list-card-body {
    height: calc(100vh - 226px);
  }
}
// --- entity-info-cards end ---
