:root[webchat-theme='theme1'] {
  --header-padding: 8px 16px;
  --header-border-radius: 12px 12px 0 0;
  --header-box-shadow: 0 4px 12px 0 var(--shadow-100);
  --header-back-color: var(--brand-primary-3-100);
  --header-info-title-font: var(--font-big-medium);
  --header-info-title-color: var(--pure-white);
  --header-info-logo-visibility: flex;
  --header-info-logo-width: 34px;
  --header-info-logo-height: 44px;
  --header-info-display: flex;
  --header-info-width: calc(100vw - 81px);
  --header-info-gap: 8px;
  --header-info-flex-direction: row;
  --header-info-white-space: nowrap;
  --header-actions-gap: 8px;
  --header-actions-icon-width: 20px;
  --header-actions-icon-height: 20px;
  --header-actions-minimize-display: flex;
  --header-actions-minimize: url('./../../icons/minimize.svg');
  --header-actions-close: url('./../../icons/power.svg');
  --header-actions-margin: 4px;
  --header-info-logo: url('./../../icons/logo.svg');
}
