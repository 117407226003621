:root[webchat-theme='theme1'] {
  --bubble-location-max-width: 190px;
  --bubble-location-padding: 8px;
  --location-card-padding: 0;
  --location-card-gap: 0;
  --location-card-map-width: 100%;
  --location-card-map-height: 136px;
  --location-card-map-margin: 0 0 8px 0;
  --location-card-map-outline: none;
  --location-card-map-radius: 8px;
  --location-card-map-shadow: none;
  --location-card-title-display: flex;
  --location-card-title-margin: 0 0 2px 0;
  --location-card-title-color: var(--pure-black);
  --location-card-description-display: flex;
  --location-card-description-margin: 0 0 2px 0;
  --location-card-description-color: var(--text-secondary);
  --location-card-title-font: var(--font-normal-medium);
  --location-card-description-font: var(--font-small-regular);
  --location-card-url-display: flex;
  --location-card-url-margin: 0 0 8px 0;
  --location-card-url-color: var(--pure-black);
  --location-card-url-font: var(--font-small-regular);
  --location-card-url-text-decoration-line: underline;
}
