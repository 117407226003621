:root[webchat-theme='theme1'] {
  --review-panel-width: 100%;
  --review-panel-height: auto;
  --review-panel-padding: 24px 32px;
  --review-panel-back-color: none;
  --review-panel-gap: 16px;
  --review-panel-position: absolute;
  --review-panel-position-left: 0;
  --review-panel-position-right: 0;
  --review-panel-position-top: 50%;
  --review-panel-position-bottom: 0;
  --review-panel-transform: translateY(-50%);
  --review-panel-title-font: var(--font-big-medium);
  --review-panel-title-color: var(--pure-black);
  --review-panel-title-text-align: center;
  --review-panel-star-group-gap: 12px;
  --review-panel-star-size: 40px;
  --review-panel-star-background: url('./../../icons/star.svg') no-repeat center center;
  --review-panel-star-hover-background: url('./../../icons/star-hover.svg') no-repeat center center;
  --review-panel-star-selected-background: url('./../../icons/star-selected.svg') no-repeat center
    center;
  --review-panel-star-text-font: var(--font-normal-regular);
  --review-panel-star-text-color: var(--text-secondary);
  --review-panel-star-text-align: center;
  --review-panel-form-gap: 16px;
  --review-panel-form-textarea-resize: none;
  --review-panel-form-textarea-border: none;
  --review-panel-form-textarea-font: var(--font-normal-regular);
  --review-panel-form-textarea-padding: 8px 4px 8px 12px;
  --review-panel-form-textarea-outline: 2px solid var(--border-primary);
  --review-panel-form-textarea-back-color: var(--pure-white);
  --review-panel-form-textarea-radius: 16px;
  --review-panel-form-input-back-color: var(--brand-primary-3-100);
  --review-panel-form-input-radius: 8px;
  --review-panel-form-input-padding: 7.5px 16px;
  --review-panel-form-input-font: var(--font-normal-medium);
  --review-panel-form-input-color: var(--pure-white);
  --review-panel-form-input-transition: background-color 0.5s ease;
  --review-panel-form-input-hover-back-color: var(--brand-primary-3-200);
  --review-panel-form-input-border: none;
  --textarea-placeholder-color: var(--text-place-holder);
}
