:root[webchat-theme='theme1'] {
  --attachment-bar-visibility: flex;
  --attachment-bar-gap: 16px;
  --attachment-bar-height: 70px;
  --attachment-bar-width: 104%;
  --attachment-bar-overflow-x: scroll;
  --attachment-bar-align-items: end;
  --attachment-image-container-position: relative;
  --attachment-image-container-width: 60px;
  --attachment-image-container-height: 60px;
  --attachment-image-container-align-self: flex-end;
  --attachment-image-width: 60px;
  --attachment-image-height: 60px;
  --attachment-image-close-position: absolute;
  --attachment-image-close-z-index: 1;
  --attachment-image-close-top: -10px;
  --attachment-image-close-right: -11px;
  --attachment-image-close-width: 16px;
  --attachment-image-close-height: 16px;
  --attachment-image-close-padding: 3px;
  --attachment-image-close-radius: 99px;
  --attachment-image-close-border: 1px solid var(--border-primary);
  --attachment-image-close-back-color: var(--pure-white);
  --attachment-image-close-icon-position: absolute;
  --attachment-image-close-icon-z-index: 1;
  --attachment-image-close-icon-width: 16px;
  --attachment-image-close-icon-height: 16px;
  --attachment-image-close-icon-background: url('./../../icons/img-delete.svg') no-repeat center
    center;
  --attachment-image-close-icon-background-size: 16px;
}
