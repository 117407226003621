:root[webchat-theme='theme1'] {
  --bottom-audio-border: none;
  --bottom-audio-align-items: center;
  --bottom-player-wrapper-padding: 8px 12px;
  --bottom-player-wrapper-back-color: var(--brand-primary-3-100);
  --bottom-player-wrapper-radius: 16px;
  --bottom-player-wrapper-width: 100%;
  --bottom-player-wrapper-height: 30px;
  --bottom-cancel-audio-button-font: var(--font-small-medium);
  --bottom-cancel-audio-button-color: var(--pure-black);
  --bottom-cancel-audio-button-align-self: center;
  --bottom-player-recording-text-font: var(--font-small-medium);
  --bottom-player-recording-text-color: var(--text-primary);
  --bottom-play-visibility: unset;
  --bottom-play-width: 16px;
  --bottom-play-height: 16px;
  --bottom-play-background: url('./../../icons/play-fill.svg') no-repeat center center;
  --bottom-play-background-size: 16px;
  --bottom-pause-visibility: unset;
  --bottom-pause-width: 16px;
  --bottom-pause-height: 12px;
  --bottom-pause-background: url('./../../icons/pause.svg') no-repeat center center;
  --bottom-pause-background-size: 12px;
  --bar-height: 10px;
  --bar-width: 2px;
  --bar-radius: 4px;
  --bar-back-color: rgba(255, 255, 255, 0.24);
  --bar-incoming-back-color: var(--brand-primary-3-100);
  --progress-height: 100%;
  --progress-back-color: var(--pure-white);
  --progress-incoming-back-color: var(--pure-white);
  --progress-radius: 4px;
  --progress-width: 0%;
  --audio-visibility: flex;
  --audio-gap: 2px;
  --audio-align-items: center;
}
