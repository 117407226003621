:root[webchat-theme='theme1'] {
  --bubble-outgoing-image-min-width: 100px;
  --bubble-outgoing-image-max-width: 204px;
  --bubble-outgoing-image-padding: 8px;
  --bubble-content-image-gap: 4px;
  --bubble-image-width: 100px;
  --bubble-image-height: 100px;
  --bubble-image-radius: 8px;
  --bubble-image-more-back-color-before: var(--dark-shades-48);
  --bubble-image-more-count-font: var(--font-big-medium);
  --bubble-image-more-count-color: var(--text-primary);
  --bubble-image-more-count-padding: 6px 10px;
}
