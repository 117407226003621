:root[webchat-theme='theme1'] {
  --font-big-bold: 700 16px/150% 'Inter Bold', sans-serif;
  --font-big-medium: 500 16px/150% 'Inter Medium', sans-serif;
  --font-big-regular: 400 16px/150% 'Inter Regular', sans-serif;
  --font-normal-medium: 500 14px/150% 'Inter Medium', sans-serif;
  --font-normal-regular: 400 14px/150% 'Inter Regular', sans-serif;
  --font-small-medium: 500 12px/150% 'Inter Medium', sans-serif;
  --font-small-regular: 400 12px/150% 'Inter Regular', sans-serif;
  --font-extra-small-regular: 400 10px/150% 'Space Grotesk Regular', sans-serif;
}
