:root[webchat-theme='theme1'] {
  --bottom-selected-file-image-container-gap: 16px;
  --bottom-selected-file-image-container-border-top: 1px solid var(--dark-shades-8);
  --bubble-file-icon-background-position: absolute;
  --bubble-file-icon-background-radius: 50%;
  --bubble-file-icon-background-top: 4px;
  --bubble-file-icon-background-left: 4px;
  --bubble-file-icon-background-width: 36px;
  --bubble-file-icon-background-height: 36px;
  --circular-progress-size: 44px;
  --circular-progress-stroke-width: 3px;
  --circular-progress-circle-stroke: var(--pure-white);
}
