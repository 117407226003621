:root[webchat-theme='theme1'] {
  --chat-bot-border-color: none;
  --chat-bot-width: 395px;
  --chat-bot-height: 600px;
  --chat-bot-border: none;
  --chat-bot-border-radius: 12px;
  --chat-bot-filter: drop-shadow(0px 4px 12px rgba(65, 65, 65, 0.12));
  --chat-bot-back-ground: var(--theme-1-back-color);
}

:root[webchat-theme='theme1'][device-type='mobile'] {
  --chat-bot-width: calc(100vw);
  --chat-bot-height: 100vh;
}
